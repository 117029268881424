<template>
  <page-list>
    <template #header>
      <el-form ref="form" :model="form" :rules="rules" label-width="120px" inline=true>
        <el-form-item label="所属门店：" prop="shopId">
          <el-select
            v-model="form.shopId"
            placeholder="请选择"
            @change="shopChangeHandle"
            clearable
            filterable
          >
            <el-option
              v-for="(item, index) in shopOptions"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="渠道" prop="channelId">
          <el-select
              v-model="form.channelId"
              placeholder="请选择"
              @change="channelOptions"
              clearable
              filterable
            >
              <el-option
                v-for="(item, index) in channelOptions"
                :key="index"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="所属店员：" prop="employeeId">
          <el-select
            v-model="form.employeeId"
            placeholder="请选择"
            :disabled="!form.shopId"
            clearable
            filterable
          >
            <el-option
              v-for="(item, index) in employeeOptions"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-select
              v-model="form.status"
              placeholder="请选择"
              clearable
            >
              <el-option
                v-for="(item, index) in ORDER_STATUS_OPTIONS"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getList()">查 询</el-button>
        </el-form-item>
      </el-form>
    </template>
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      stripe
      v-loading="loading"
      element-loading-background="transparent"
      @sort-change="sortChangeHandle"
    >
      <el-table-column
        prop="shopInfo.name"
        label="商家名称"
        header-align="center"
        align="center"
        min-width="150"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="userInfo.username"
        label="用户名"
        header-align="center"
        align="center"
        min-width="80"
      >
      </el-table-column>
      <el-table-column
        prop="outTradeNo"
        label="订单编号"
        header-align="center"
        align="center"
        min-width="200"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="numberId"
        label="取餐号"
        header-align="center"
        align="center"
        min-width="80"
      ></el-table-column>
      <el-table-column prop="orderProductList" label="商品信息" min-width="200" header-align="center" align="center">
        <template v-slot="scope">
          <el-tooltip placement="top" :open-delay="500" effect="dark">
            <div >{{getorderProductListStr(scope.row.orderProductList)}}</div>
            <template #content> 
              <div  v-for="productInfo in scope.row.orderProductList" :key="productInfo.id" class="text item m-text-left">
                <span class="product-title">{{ productInfo.title }}</span>&emsp;
                <span></span> <br/>&emsp;
                <span>{{ "￥" + productInfo.price + "x" +productInfo.number + "=" + productInfo.price*productInfo.number}}</span>
              </div>
            </template>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        prop="orderPrice"
        label="订单金额"
        header-align="center"
        align="center"
        min-width="80"
      >
      </el-table-column>
      <el-table-column
        prop="discountPrice"
        label="优惠价格"
        header-align="center"
        align="center"
        min-width="80"
      ></el-table-column>
      <el-table-column
        prop="deliveryPrice"
        label="配送费"
        header-align="center"
        align="center"
        min-width="80"
      ></el-table-column>
      <el-table-column
        prop="totalPrice"
        label="实付价格"
        header-align="center"
        align="center"
        min-width="80"
      ></el-table-column>
      <el-table-column
        prop="payType"
        label="付款方式"
        header-align="center"
        align="center"
        min-width="80"
      ><template v-slot="scope">
          <span
            v-if="scope.row.payType"
            >{{ " " + ORDER_PAY_TYPE[scope.row.payType].label }}</span
          >
        </template></el-table-column>
      <el-table-column
        prop="type"
        label="购买类型"
        header-align="center"
        align="center"
        min-width="80"
      ><template v-slot="scope">
          <span
            v-if="scope.row.type"
            >{{ " " + ORDER_BUY_TYPE[scope.row.type].label }}</span
          >
        </template></el-table-column>
      <el-table-column
        prop="orderType"
        label="订单类型"
        header-align="center"
        align="center"
        min-width="80"
      ><template v-slot="scope">
          <span
            v-if="scope.row.orderType"
            >{{ " " + ORDER_TYPE[scope.row.orderType].label }}</span
          >
        </template></el-table-column>
      <el-table-column
        prop="status"
        label="订单状态"
        header-align="center"
        align="center"
        min-width="100"
        show-overflow-tooltip
      >
        <template v-slot="scope">
          <span
            class="fa fa-circle"
            :style="{
              color: ORDER_STATUS[!!scope.row.status].color,
            }"
            >{{ " " + ORDER_STATUS[!!scope.row.status].label }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="havePaid"
        label="支付"
        header-align="center"
        align="center"
        min-width="80"
        show-overflow-tooltip
      >
        <template v-slot="scope">
          <span
            class="fa fa-circle"
            :style="{
              color: YES_OR_NO[!!scope.row.havePaid].color,
            }"
            >{{ " " + YES_OR_NO[!!scope.row.havePaid].label }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="haveMade"
        label="出单/配送"
        header-align="center"
        align="center"
        min-width="90"
        show-overflow-tooltip
      >
        <template v-slot="scope">
          <span
            class="fa fa-circle"
            :style="{
              color: YES_OR_NO[!!scope.row.haveMade].color,
            }"
            >{{ " " + YES_OR_NO[!!scope.row.haveMade].label }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="haveReceived"
        label="签收"
        header-align="center"
        align="center"
        min-width="80"
        show-overflow-tooltip
      >
        <template v-slot="scope">
          <span
            class="fa fa-circle"
            :style="{
              color: YES_OR_NO[!!scope.row.haveReceived].color,
            }"
            >{{ " " + YES_OR_NO[!!scope.row.haveReceived].label }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="shopbox"
        label="包厢"
        header-align="center"
        align="center"
        min-width="60"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="employeeInfo.name"
        label="店员"
        header-align="center"
        align="center"
        min-width="60"
        show-overflow-tooltip
      >

      </el-table-column>
      <el-table-column
        prop="createtime"
        label="创建时间"
        header-align="center"
        align="center"
        min-width="150px"
        :formatter="
          (row) => $moment.unix(row.createtime).format('yyyy-MM-DD HH:mm:ss')
        "
      >
      </el-table-column>
      <el-table-column
        prop="updatetime"
        label="更新时间"
        header-align="center"
        align="center"
        min-width="150px"
        :formatter="
          (row) => $moment.unix(row.updatetime).format('yyyy-MM-DD HH:mm:ss')
        "
      >
      </el-table-column>
    </el-table>
    <pagination
      v-model="paginationData"
      :total="total"
      @change="getList"
    ></pagination>
  </page-list>
</template>

<script>
import { listByPage } from "@/apis/unidrink/order";
import { getChannelOptions } from "@/apis/unidrink/channel";
import { getShopOptions } from "@/apis/unidrink/shop";
import { listAll } from "@/apis/unidrink/employee";
import { cloneDeep } from "lodash";
import { msgErr, msgSuc } from "@/helpers/message";
import {
  YES_OR_NO,
  ORDER_STATUS,
  ORDER_STATUS_OPTIONS,
  ORDER_TYPE,
  ORDER_PAY_TYPE,
  ORDER_BUY_TYPE
} from "@/helpers/constants";
export default {
  data() {
    return {
      ORDER_STATUS: ORDER_STATUS,
      ORDER_STATUS_OPTIONS: ORDER_STATUS_OPTIONS,
      YES_OR_NO: YES_OR_NO,
      ORDER_TYPE: ORDER_TYPE,
      ORDER_PAY_TYPE: ORDER_PAY_TYPE,
      ORDER_BUY_TYPE: ORDER_BUY_TYPE,
      tableData: [],
      paginationData: {},
      initForm: {
        sort: "id",
        order: "desc",
        status: 1
      },
      form: {},
      total: 0,
      shopOptions: [],
      employeeOptions: [],
      channelOptions: [],
      loading: false,
      dialogVisible: false,
      editMode: "add",
      editData: {}
    };
  },
  created() {},
  mounted() {
    getShopOptions(this.shopOptions);
  },
  activated() {
    this.form = cloneDeep(this.initForm);
    // this.form.adminId = this.$store.getters.userInfo.id
    this.getList();
    getChannelOptions(this.channelOptions, {},false);
  },
  methods: {
    getList() {
      this.loading = true;
      listByPage({ ...this.form, ...this.paginationData })
        .then(({ code, result, message }) => {
          if (code !== "0000") {
            msgErr(message);
            return;
          }
          const { list, total } = result;
          this.tableData = list;
          this.total = total;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getorderProductListStr(orderProductList) {
      let orderProductListStr = "";
      for (let i = 0; i < orderProductList.length; i++) {
        orderProductListStr += orderProductList[i].title + ",";
      }
      orderProductListStr = orderProductListStr.substring(0,orderProductListStr.length-1);
      if(orderProductListStr.length>12){
        orderProductListStr= orderProductListStr.substring(0,12);
        orderProductListStr += "...";
      }
      return orderProductListStr;
    },

    sortChangeHandle({ prop, order }) {
      if (prop && order) {
        this.form = {
          sort: prop,
          order: order === "descending" ? "desc" : "asc"
        };
      } else {
        this.form = cloneDeep(this.initForm);
      }
      this.getList();
    },

    editHandle(row) {
      this.editMode = "edit";
      this.dialogVisible = true;
      this.editData = row;
    },

    shopChangeHandle(){
      console.log("shopChangeHandle");
      this.getEmployeeOptions();
      this.getChannelOptions();
    },

    getChannelOptions(){
      this.form.channelId = "";
      this.channelOptions=[];
      if(this.form.shopId){
        getChannelOptions(this.channelOptions, {shopId:this.form.shopId},false);
      }else{
        getChannelOptions(this.channelOptions, {},false);
      }
    },

    getEmployeeOptions(){
      this.form.employeeId = "";
      this.employeeOptions=[];
      if(this.form.shopId){
        listAll({shopId:this.form.shopId}).then(({ code, result, message }) => {
          if (code !== "0000") {
            msgErr(message);
            return;
          }
          const { list, total } = result;
          this.employeeOptions.push(...list.map((row) => {
            return {
              name: row.name.replace(/[&nbsp;|└]/g, ""),
              id: row.id,
            };
          }));
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.product-title {
  font-weight: bold;
}
.amount {
  color: red;
}
</style>
